
import PictureInput from 'vue-picture-input';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { CompanyUpdate } from '@/settings/application/uses_cases/company/update/CompanyUpdate';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import i18n from '@/core/plugins/i18n';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import SearchTerritories from '@/core/components/shared/SearchTerritories.vue';
import Draggable from 'vuedraggable';
import { PricingZone } from '@/settings/domain/PricingZone/PricingZone';
import { PricingZoneFindAll } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindAll';
import IntegrationsForm from './Integrations/IntegrationsForm.vue';
import ConfigServerForm from './EmailServerConfig/ConfigServerForm.vue';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { Company } from '@/settings/domain/company/Company';
import { getLocalstorageItem } from '@/core/config/LocalStorageVariables';
import TerritorySearch from '@/core/components/shared/TerritorySearch.vue';
import { Currency } from '@/settings/domain/currency/Currency';
import CurrencySearch from '@/core/components/shared/CurrencySearch.vue';

@Component({
  name: 'CompanySettings',
  components: {
    PictureInput,
    SearchTerritories,
    Draggable,
    IntegrationsForm,
    ConfigServerForm,
    TerritorySearch,
    CurrencySearch
  }
})
export default class CompanySettings extends Vue {
  $refs!: {
    pictureInput: HTMLFormElement;
    fileInput: HTMLFormElement;
  };
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly currencyFindAllcall!: CurrencyFindAll;
  @Inject(TYPES.UPDATE_COMPANY)
  readonly companyUpdate!: CompanyUpdate;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.PRICING_ZONE_FIND_ALL)
  readonly findAllPricingZones!: PricingZoneFindAll;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;
  @Inject(TYPES.SAVE_FILE)
  readonly saveFile!: CreateFileV2;

  target = i18n.t('general.company');
  isLoading = false;
  fullPage = true;
  nameCurrencies: Currency[] = [];
  disabled = true;
  isDisabled = false;
  optionshierarchy = [
    { label: this.$t('general.supplier'), code: 'supplier' },
    { label: this.$t('general.pricingZone'), code: 'zone' },
    { label: this.$t('general.customer'), code: 'customer' }
  ];
  file: File | null = null;
  zonesList: PricingZone[] = [];
  form = {
    urlImage: '',
    urlPdf: '',
    urlBase64Logo: '',
    image: ''
  };

  formCompany: Company = new Company();
  selectedOrigin: Territories | null = null;
  selectedCurrency: Currency | null = null;

  origin: Territories[] = [];

  mounted() {
    this.getData();
  }

  getData() {
    this.findCompanyData();
    // this.findPricingZones();
    this.currencyFindAll();
  }

  companyId = getLocalstorageItem('BUSINESS_ID');

  get zonePricingOptions() {
    return [
      {
        text: `${this.$t('general.country')}`,
        value: 'country'
      },
      {
        text: `${this.$t('general.state')}`,
        value: 'state'
      },
      {
        text: `${this.$t('general.city')}`,
        value: 'city'
      }
    ];
  }

  get disablePricingLevel() {
    return this.zonesList.length > 0;
  }

  get operationalProfiles() {
    const arrayProfile = [
      {
        text: 'Wms',
        value: 'wms',
        notEnabled: true
      },
      {
        text: 'Courier',
        value: 'courier',
        notEnabled: true
      },
      {
        text: 'Freight',
        value: 'freight',
        notEnabled: true
      }
    ];

    return arrayProfile;
  }

  get urlToPdf() {
    return this.findFileByName.internalExecute(this.formCompany.urlPdf);
  }

  //METHODS
  async findTerritriesFromView(query: string) {
    try {
      if (query.length < 4) return;

      this.isLoading = true;
      const res = await this.searchTerritoriesByQueryParameter.internalExecute(query.toUpperCase());

      this.origin = res;

      return res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  currencyFindAll() {
    this.currencyFindAllcall
      .execute()
      .then((response: any) => {
        this.nameCurrencies = response;
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  async findCompanyData() {
    if (!this.companyId) return;

    try {
      this.isLoading = true;
      const response = await this.companyFindById.internalExecute(this.companyId);
      this.formCompany = response;

      // Buscamos la imagen de la compañía y la convertimos a base64 para cargarla al componente
      if (response.urlLogo) {
        const file = this.findFileByName.internalExecute(response.urlLogo);
        if (file) {
          this.form.urlBase64Logo = file;
        }
      }

      // Si se recibe bien la respuesta, se procede a buscar el territorio

      const territories = await this.findTerritriesFromView(response.territory);

      if (!territories) return;

      //Buscamos el territorio dentro de la respuesta
      if (territories.length > 0) {
        const findTerritorry = territories.find(territory => territory.city === response.territoryId) || null;
        this.selectedOrigin = findTerritorry;
      }

      // Buscamos la moneda dentro de la lista de monedas
      if (this.nameCurrencies.length > 0 && response.defaultCurrency !== '') {
        const findCurrency =
          this.nameCurrencies.find(currency => currency.currencyCode === response.defaultCurrency) || null;
        this.selectedCurrency = findCurrency;
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  onChanged() {
    if (this.$refs.pictureInput.file) {
      this.form.image = this.$refs.pictureInput.file;
    }
  }

  async saveImage() {
    try {
      if (!this.form.image) return this.formCompany.urlLogo;
      if (this.$refs.pictureInput.image === '') return '';
      const file = this.$refs.pictureInput.file;
      // Cambiamos el nombre del archivo
      const fileNewName = `profile.${file.name.split('.').pop()}`;

      // Creamos un nuevo archivo con el nombre modificado
      const newFile = new File([file], fileNewName, { type: file.type });

      //Subimos dicho archivo al servidor
      const response = await this.saveFile.internalExecute({
        files: [newFile]
      });

      // Asignamos la respuesta al campo de la imagen
      if (!Array.isArray(response)) return response;

      // Vaciamos el campo de la imagen para que no se suba de nuevo
      this.form.image = '';

      return '';
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  onRemoved() {
    this.form.image = '';
    this.formCompany.urlLogo = '';
  }
  currencyWithRate({ currencyCode, description }: any) {
    return `${currencyCode} — [${description}]`;
  }

  async uploadPoliticalFile() {
    try {
      if (!this.file) return this.formCompany.urlPdf;
      const file = this.file;
      // Cambiamos el nombre del archivo
      const fileNewName = `politics.${file.name.split('.').pop()}`;

      // Creamos un nuevo archivo con el nombre modificado
      const newFile = new File([file], fileNewName, { type: file.type });

      //Subimos dicho archivo al servidor
      const response = await this.saveFile.internalExecute({
        files: [newFile]
      });

      // Vaciamos el campo del archivo para que no se suba de nuevo
      this.file = null;

      // Asignamos la respuesta al campo
      if (!Array.isArray(response)) return response;

      return '';
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findPricingZones() {
    try {
      this.isLoading = true;
      const res = await this.findAllPricingZones.execute();
      this.zonesList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async save() {
    try {
      this.isLoading = true;

      const payload = Object.assign({}, this.formCompany);

      // Metemos el codigo de la moneda
      payload.defaultCurrency = this.selectedCurrency?.currencyCode || '';

      // Usamos promesas para subir la imagen y el archivo político
      const promises = [this.saveImage(), this.uploadPoliticalFile()];

      // Esperamos a que ambas promesas se resuelvan
      const [urlLogo, urlPdf] = await Promise.all(promises);

      // Asignamos las URL a los campos correspondientes
      payload.urlLogo = urlLogo;
      payload.urlPdf = urlPdf;

      // Si el origen está seleccionado, se asigna a la compañía su ID y el nombre completo
      if (this.selectedOrigin) {
        payload.territory = this.selectedOrigin.city;
        payload.territory = this.selectedOrigin.fullName;
      }

      await this.companyUpdate.internalExecute(payload);
      this.getData();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
